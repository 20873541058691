<template>
	<div class="apartments-nav-arrows" v-if="nextApartment || previousApartment">
		<fp-icon-button symbol="angel-right" @click="goToPreviousApartmentPage" :disabled="!previousApartment"
			:title="$t('הדירה הקודמת')"></fp-icon-button>
		<div class="separator">
			<fp-icon symbol="dot"></fp-icon>
		</div>
		<fp-icon-button symbol="angel-left" @click="goToNextApartmentPage" :disabled="!nextApartment"
			:title="$t('הדירה הבאה')"></fp-icon-button>
	</div>
	<div class="apartment-modal" v-bind="$attrs">
		<template v-if="apartment">
			<div class="modal-header">

				<div class="modal-title flex between fullWidth" tabindex="0" @click="goToApartmentPage">
					<div class="flex align-center">
					
						<apartment-availability :available="apartment.available"></apartment-availability>
						<span class="availabilityStatus">{{ apartment.available }}</span>
					</div>
					<apartment-fav-button v-if="showFavsButton" :apt-id="apartment.id"
						:showLinkToFavs="false"></apartment-fav-button>
						<close-btn @click.stop="close"></close-btn>
				</div>
			</div>
			<div class="modal-content">
				<div class="apt-thumbnail" :class="{ clickable: aptIsClickable }"
					v-if="showThumbnail && apartment.type_thumbnail" tabindex="1" role="button"
					@click="goToApartmentPage">
					<img :src="apartment.type_thumbnail" :alt="apartment.name">
				</div>
				<div class="floorName">
					{{ $t('קומה') }}
				</div>
				<div class="flex">
					<div class="floorNumber">
						{{ apartment.floor_name }}
					</div>
					<div class="floorDetails"
						v-if="filteredApartmentBlockFieldsOrder && filteredApartmentBlockFieldsOrder.length">
						<ul>
              <template v-for="(detail, index) in filteredApartmentBlockFieldsOrder" :key="index">
                <li v-if="detail.title && apartmentDetail(detail)">
                  <span>
                    - {{ detail.title }}: {{ apartmentDetail(detail) }}
                  </span>
                </li>
              </template>
						</ul>
					</div>
				</div>
				<div v-if="apartmentPrice" class="apt-features">
					<hr v-if="!isMobile">
					<div class="apt-price flex flex-row flex-nowrap">
						<span class="flex-grow-1">{{ $t('מחיר') }}</span>
						<span v-html="apartmentPrice"></span>
					</div>
				</div>
				<template v-if="showButtons && (showApartmentShareButton || apartmentContactButton.show)">
					<div class="social-buttons flex between">
						<fp-icon-button v-if="apartmentContactButton.show" @click="goToApartmentPage"
							class="btn btn-secondary-outline" symbol="phone-ring" :height="'2.5rem'"
							:iconBackGroundColor="colors.primary" :title="$t('דירה')">
							<span v-if="apartmentContactButton.title && apartmentContactButton.translate">{{
									$t(apartmentContactButton.title) }}
								</span>
							<span v-else-if="apartmentContactButton.title">{{ apartmentContactButton.title }}</span>
							<span v-else>{{ $t('דירה') }}</span>
						</fp-icon-button>
						<fp-icon-button v-if="floorsEnabled" @click="onSetFloorMode"
							class="btn btn-secondary-outline" symbol="phone-ring" :height="'2.5rem'"
							:iconBackGroundColor="colors.primary" >
							<span>{{ floorMode ? $t('חזרה') : $t('קומה') }}</span>
						</fp-icon-button>
						<div v-else class="cursor-pointer">
							<FpNewIcon iconName="share_btn" class="shareButton" size="2.25rem"
								@click="showBottomButtons = !showBottomButtons" />
						</div>
					</div>
				</template>

				<div v-if="showLink" class="link-button-wrapper">
					<fp-button @click="goToApartmentPage">{{ $t('מעבר לדירה') }}</fp-button>
				</div>
				<related-apartments
          v-if="showRelatedApartments && !apartmentOnly && apartment.related_products"
          :apartments="apartment.related_products"
        ></related-apartments>
			</div>
			<transition appear>
				<div class="bottombuttons" v-if="showBottomButtons">
					<fp-icon-button v-for="(button, btnId) in bottomButtons" :key="btnId"
						@click="$store.commit('toggleContactModal')" class="btn btn-secondary-outline"
						:symbol="button.iconName" :iconColor="button.iconColor"
						:iconBackGroundColor="button.iconBackGroundColor" :title="button.name">
						<span>{{ button.name }}</span>
					</fp-icon-button>

				</div>

			</transition>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import FpButton from "@/components/buttons/FpButton"
import FpIconButton from "@/components/buttons/FpIconButton"
import { delay } from "@/utils"
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
import RelatedApartments from "@/components/RelatedApartments"
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability.vue"
import FpIcon from "@/components/FpIcon.vue";
import CloseBtn from "@/components/buttons/CloseBtn";

export default {
	name: "ApartmentModal",
	props: {
		apartment: {
			required: false,
			default: null
		},
		showFavsButton: {
			required: false,
			type: Boolean,
			default: true
		},
		nextApartment: {
			required: false,
			type: Object,
			default: null
		},
		previousApartment: {
			required: false,
			type: Object,
			default: null
		},
		showButtons: {
			type: Boolean,
			default: true
		},
		showLink: {
			type: Boolean,
			default: false
		},
		navigateOnButtonClick: {
			type: Boolean,
			default: false
		},
		showThumbnail: {
			type: Boolean,
			default: false
		},
		// setfloorMode: {
		// 	type: Function,
		// 	default: () => { }
		// },
	},
	emits: ['close', 'apartment-clicked', 'next-apartment-clicked', 'previous-apartment-clicked', 'show-apartment-floor'],
	components: {
		FpIcon,
		ApartmentAvailability,
		FpNewIcon,
		RelatedApartments,
		FpIconButton,
		FpButton,
		ApartmentFavButton,
		CloseBtn
	},
	computed: {
		aptIsClickable() {
			return this.apartmentPageEnabled && this.apartment.type_show_page
		},
		isApartmentPage() {
			return this.$route.name === 'apartment'
		},
		apartmentPrice() {
			if (this.apartment && this.apartmentBlockFieldsOrder) {
				const showPrice = this.apartmentBlockFieldsOrder.findIndex(field => field.field === 'product_price') !== -1;
				if (showPrice) {
					if (this.apartment.price) {
						return this.apartment.price;
					}
					const price = this.apartment.display_fields.find(field => field.field === 'product_price');
					if (price && price.value) {
						if (Array.isArray(price.value)) {
							return price.value[0];
						}
						return price.value
					}
				}
			}
			return null;
		},
		floorsEnabled() {
			if (this.apartment && this.apartment.type_building ) {
				console.log("this.apartment", this.apartment)
				return this.apartment.type_building.floors_active 
			}
			return null;
		},

		filteredApartmentBlockFieldsOrder() {
			if (this.apartmentBlockFieldsOrder && this.apartmentBlockFieldsOrder.length > 0) {
				return this.apartmentBlockFieldsOrder.filter(field => field.field !== 'product_price');
			}
			return [];
		},
		...mapState(['currentProjectId', 'apartmentContent']),
		...mapGetters([
			'isMobile',
			'isDesktop',
			'isTablet',
			'apartmentOnly',
			'apartmentPageEnabled',
			'showApartmentShareButton',
			'apartmentContactButton',
			'colors',
			'apartmentBlockFieldsOrder',
			'floorMode',
      'showRelatedApartments'
		])
	},
	watch: {
		apartment(apartment) {
			if (!this.isMobile) {
				if (apartment && apartment.buttons && Array.isArray(apartment.buttons) && apartment.buttons[0]) {
					this.contentChange(apartment.buttons[0])
				} else if (this.apartmentContent) {
					this.$store.commit('apartmentContent', null)
				}
			}

		}
	},
	data() {
		return {
			content: null,
			showBottomButtons: false,
			
			// floorsEnabled: false,
			bottomButtons: [
				{
					name: 'שתפו דירה',
					iconName: "whatsapp",
					iconColor: "white",
					iconBackGroundColor: "rgba(8, 187, 5, 1)"
				},
				{
					name: 'העתיקו קישור',
					iconName: "copy",
					iconColor: "rgba(71, 71, 71, 1)",
					iconBackGroundColor: "transparent"
				},
				{
					name: 'שתפו עמוד',
					iconName: "whatsapp",
					iconColor: "white",
					iconBackGroundColor: "rgba(8, 187, 5, 1)"
				},
				{
					name: 'התקשרו',
					iconName: "phone-ring",
					iconColor: "rgba(71, 71, 71, 1)",
					iconBackGroundColor: "transparent"
				},
			]
		}
	},
	methods: {
		close() {
			if (this.isApartmentPage) {
				this.$router.push({ name: 'apartments', params: { id: this.currentProjectId } })
			}
			this.$emit('close')
		},
		isCurrentContent(button) {
			return JSON.stringify(this.apartmentContent) === JSON.stringify(button);

		},
		async contentChange(content) {
			if (this.apartmentContent) {
				this.$store.commit('apartmentContent', null)
				await delay(300)
			}
			this.$store.commit('apartmentContent', content)
			if (this.navigateOnButtonClick) {
				await this.$router.push({
					name: 'apartment',
					params: { projectId: this.currentProjectId, apartmentId: this.apartment.id }
				})
			}
		},
		shareApartment() {
			this.$store.dispatch('shareDialogToggle', { show: true, apartment: this.apartment })
		},
		goToApartmentPage() {
			if (this.aptIsClickable) {
				this.$emit('apartment-clicked', this.apartment)
			}
		},
		onSetFloorMode() {
			console.log("this.floorMode", this.floorMode)
			this.$frameBus.emit('message', {
					action: 'show-apartment-floor',
					apartment: !this.floorMode ? this.apartment : null
			})
			this.$store.commit('setFloorMode', !this.floorMode)
			// this.setfloorMode(!this.floorMode);
		},
		goToNextApartmentPage() {
			if (!this.isApartmentPage) {
				this.$emit('next-apartment-clicked', this.nextApartment)
			}
		},
		goToPreviousApartmentPage() {
			if (!this.isApartmentPage) {
				this.$emit('previous-apartment-clicked', this.previousApartment)
			}
		},
		apartmentDetail(detail) {
			
			if (this.apartment && this.apartment.display_fields && this.apartment.display_fields.length > 0) {
				const field = this.apartment.display_fields.find((field) => field.field === detail.field);
				return field?.value
			}
			return '';
		}
	}
}
</script>

<style scoped lang="scss">
.apartments-nav-arrows {
	width: 311px;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	padding: .5rem .5rem;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);

	.separator svg {
		width: 10px;
	}
}

.apartment-modal {
	border-radius: 0.3125rem;
	width: 20rem;
	position: relative;
	z-index: 12;

	.floorName {
		color: #FFF;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 700;
		margin-right: 1.3rem;
		margin-left: 1.3rem;

	}

	.floorNumber {
		color: #737373;
		font-size: 5.5rem;
		font-style: normal;
		font-weight: 500;
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.floorDetails {
		color: #FFF;
		margin-right: 1.5rem;
		font-size: 0.9375rem;
		padding-top: .3rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.1875rem;
	}

	.social-buttons {

		margin-top: 15px;
		margin-bottom: 15px;

		gap: .8rem;
		align-items: center;

		/*button {
						color: #fff;
			min-height: 0;
			font-size: 0.875rem;
			border-radius: 6.25rem;
			height: 2.125rem;

				}	*/
	}

	.bottombuttons {
		display: flex;
		flex-wrap: wrap;
		border-radius: 0.3125rem;

		&.v-enter-active,
		&.v-leave-active {
			transition: all .3s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			margin-top: -10rem;
		}
	}

	.bottombuttons button {
		flex-basis: calc(50% - 20px);
		/* subtract the total margin */
		margin: 9px;
		/* creates a 20px gap between items */
	}

	.modal-content {

		z-index: 1;
	}

	.modal-header {
		padding: 0.9375rem;
		display: flex;
		flex-flow: row-reverse;
		justify-content: space-between;
		align-items: center;
		gap: 5px;

		.modal-title {
			display: flex;
			align-items: center;
			gap: 15px;
			cursor: pointer;
		}

		.availabilityStatus {
			margin: 0 .5rem;
		}
	}

	hr {

		margin: 0;
	}

	.apt-price {
		padding: 0.9375rem 0.625rem;

		:deep(span.woocommerce-Price-currencySymbol) {
			margin: 0 0.3125rem;
		}
	}

	.link-button-wrapper {
		text-align: center;
		margin-top: 10px;
	}

	.apt-thumbnail {
		margin-bottom: 1.5rem;
		overflow: hidden;
	}

	.apt-thumbnail img {
		height: 15rem;
		width: auto;
		max-width: none;

	}

	.clickable {
		cursor: pointer;
	}

	:deep(.model-frame-wrapper) {
		margin-right: auto;
		margin-left: auto;
	}
}

.is-mobile {
	.apartment-modal {

		.modal-header {
			flex-flow: row;
		}

		.modal-content {
			display: flex;
			flex-flow: column;

			.related-apartments {
				width: 100%;
			}

			.apt-buttons {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 15px;
				width: 100%;
				padding: 5px 0 10px;

				button {
					margin-bottom: 0;
				}
			}

			.apt-features {
				margin: 10px 0 5px;
				padding: 15px 0;
			}
		}

		.apt-price {
			border: solid 1px #B7B6B6;
			border-radius: 5px;
		}
		.floorName {
			display: none;
	
		}
		.floorNumber {
			display: none;
		}
		.floorDetails {
			display: none;
		}
		.apt-thumbnail {
			display: none;
		}
	
		.apt-thumbnail img {
			display: none;
	
		}
		.social-buttons {
			padding: 1rem 1rem;
			width: 100vw;
		}
	}
}

.fp-side-menu-wrapper+.fp-app-wrapper {

	.apartment-modal {
		display: flex;
		flex-flow: column;
		border-radius: 0.3125rem;
		background: #202020;
		margin-left: .8rem;
		margin-top: 2.5rem;

		.modal-content {
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			flex-grow: 1;
			background: #202020;

			>.apt-features:first-child {
				margin-top: calc(10vh);
			}
		}

		.apt-buttons,
		.social-buttons {
			padding: 1rem 1rem 0;
			margin-top: 0;

			button.btn.btn-secondary-outline {
				width: 75%;
				min-height: 0;
				height: 2.5rem;


			}
		}


		/*.apt-buttons {
						margin: auto 0;
						flex-grow: 1;
						display: flex;
						flex-flow: column;
						align-items: stretch;

						.fp-nf-btn.expand-block {
								display: block;
						}

						.fp-nf-btn {
								color: #fff;
								font-size: 1.125rem;
								text-align: start;
								position: relative;
						}

						.fp-nf-btn::before {
								content: " | ";
								color: var(--primary);
								visibility: hidden;
								position: absolute;
								left: 100%;
								top: 0;
						}

						.fp-nf-btn.active,
						.fp-nf-btn:hover {
								font-weight: 500;
						}

						.fp-nf-btn.active::before,
						.fp-nf-btn:hover::before{
								visibility: visible;
						}
				}*/
	}

}
</style>
